.pageContainer {
  width: 100%;
  height: 100%;
  padding: 20px;
}
.nfts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  margin: 0 auto;
  max-width: 100%;
}

//圆角边框卡片带阴影
.nft-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  height: 140px;
  border-radius: 5px;
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.2);
  margin: 10px;
  padding: 5px;
}

//nft简介，超过部分用省略号显示
.nft-name {
  width: 100px;
  font-size: 8px;
  color: rgb(55, 55, 55);
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nft-img {
  width: 80px;
  height: 80px;
}

//底部容器
.bottom-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  max-width: 100%;
}

//蓝色边框发光按钮
.nice-button {
  border: 1px solid #00a2ff;
  border-radius: 5px;
  background-color: #00a2ff;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.input-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-left: 10px;
}

//蓝色边框发光输入框
.text-input {
  width: 24em;
  border: 1px solid #00a2ff;
  border-radius: 5px;
  background-color: #ffffff;
  color: rgb(58, 58, 58);
  font-size: 1em;
  font-weight: bold;
  margin-left: 10px;
  padding: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
