@import 'baseColor.less';
.full-screen-container{
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: @bg-deep;
}
.full-screen-container h1{
    color: @textWhite;
    font-size: 2em;
    margin: 0;
    padding: 0;
    text-align: center;
    font-weight: bold;
}
.full-screen-container .link{
    color: @lightBlue;
}